.news-table-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.toolbar-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
}

.search-icon {
    position: absolute;
    left: 8px;
    color: #6b7280;
    pointer-events: none;
}

.search-input {
    padding: 6px 8px 6px 32px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 13px;
    width: 320px;
    transition: border-color 0.2s ease;
    height: 34px;
}

.search-input:focus {
    outline: none;
    border-color: #3b82f6;
}

.status-filter {
    position: relative;
}

.status-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 4px;
    min-width: 120px;
}

.filter-button {
    padding: 6px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #374151;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    height: 34px;
}

.filter-button:hover {
    background: #f3f4f6;
}

.filter-option {
    padding: 6px 12px;
    width: 100%;
    text-align: left;
    border: none;
    background: white;
    color: #374151;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.filter-option:hover {
    background: #f3f4f6;
}

.bulk-actions-container {
    position: relative;
}

.bulk-actions-button {
    padding: 6px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: white;
    color: #374151;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.2s ease;
    height: 34px;
}

.bulk-actions-button:hover {
    background: #f3f4f6;
}

.bulk-actions-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    margin-top: 4px;
    min-width: 160px;
}

.bulk-action-button {
    padding: 6px 12px;
    width: 100%;
    text-align: left;
    border: none;
    background: white;
    color: #374151;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.2s ease;
}

.bulk-action-button:hover {
    background: #f3f4f6;
}

.refresh-button {
    background-color: #2a5298 !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 6px 12px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
    font-size: 13px !important;
    height: 34px !important;
    position: relative !important;
    z-index: 1 !important;
}

.refresh-button:hover {
    background-color: #23487f !important;
    color: white !important;
}

.refresh-button:disabled {
    background-color: #6c757d !important;
    cursor: not-allowed !important;
}

.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Добавим новые стили */
.action-button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    transition: all 0.2s ease;
}

.action-button:hover {
    background-color: #f3f4f6;
}

.bulk-actions-counter {
    background-color: #3b82f6;
    color: white;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
    margin-left: 4px;
}

.reset-filter-button {
    background-color: #fff;
    border: 1px solid #dc2626;
    border-radius: 4px;
    padding: 6px 12px;
    color: #dc2626;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    height: 31px;
}

.reset-filter-button:hover {
    background-color: #fee2e2;
    border-color: #dc2626;
}

.reset-filter-button .close-icon {
    font-size: 16px;
    line-height: 1;
    margin-left: 4px;
}

.refresh-button-icon {
    font-size: 18px;
    color: #ef4444;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1;
    pointer-events: none;
}
