/* Pagination styles */
.news-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f8fafc;
    border-top: 1px solid #e5e7eb;
    margin-top: 1px;
}

.news-pagination-rows-per-page {
    display: flex;
    align-items: center;
    gap: 12px;
}

.news-pagination-rows-per-page-label {
    font-size: 14px;
    color: #64748b;
}

.news-pagination-rows-per-page-select {
    padding: 4px 24px 4px 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    color: #1e293b;
    cursor: pointer;
    outline: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 12px;
}

.news-pagination-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.news-pagination-button {
    padding: 4px 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: white;
    color: #1e293b;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
}

.news-pagination-button:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
}

.news-pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f8fafc;
}

.news-pagination-info {
    font-size: 14px;
    color: #64748b;
    padding: 0 8px;
    min-width: 120px;
    text-align: center;
}
