.news-form-container {
    padding: 20px;
    display: flex;
    gap: 40px;
}

.news-form-section {
    flex: 0 0 500px;
}

.news-form-title {
    margin-bottom: 30px;
    color: #1e3c72;
    font-size: 24px;
    font-weight: 500;
}

.form-group {
    margin-bottom: 20px;
}

.form-label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.form-label-error {
    color: #dc2626;
}

.form-error {
    padding: 12px;
    background-color: #fff5f5;
    color: #dc2626;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid #fecaca;
}

.form-select {
    width: 150px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    outline: none;
    background-color: white;
    cursor: pointer;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.form-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.form-input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    outline: none;
    transition: all 0.2s ease;
}

.form-input.error {
    border: 1px solid #dc2626;
    background-color: #fff5f5;
}

.form-input:focus {
    border-color: #2a5298;
    box-shadow: 0 0 0 2px rgba(42, 82, 152, 0.1);
}

.character-counter {
    position: absolute;
    top: -24px;
    right: 0;
    font-size: 14px;
    transition: color 0.2s ease;
}

.btn {
    padding: 10px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.btn-primary {
    background-color: #2a5298;
    color: white;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.btn-secondary {
    background-color: white;
    color: #666;
    border: 1px solid #ddd;
}

.btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Preview styles */
.preview-container {
    flex: 1;
}

.preview-title {
    margin-bottom: 20px;
    color: #1e3c72;
    font-size: 20px;
    font-weight: 500;
}

.preview-content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.aspect-ratio-buttons {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}

.aspect-ratio-btn {
    display: inline-block;
    padding: 4px 12px;
    background-color: #f3f4f6;
    border-radius: 4px;
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.aspect-ratio-btn.active {
    background-color: #fff;
    color: #2a5298;
}

.error-message {
    font-size: 12px;
    color: #dc2626;
    margin-top: 4px;
}

/* Стили из InputWrapper.css */
.label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.label-error {
    color: #dc2626;
}

/* Стили для UTM-меток */
.utm-select {
    position: relative;
}

.utm-select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    cursor: pointer;
    background-color: white;
    transition: all 0.2s;
    font-size: 14px;
}

.utm-select-header:hover {
    border-color: #2a5298;
}

.utm-options {
    position: absolute;
    margin-top: 4px;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
    z-index: 10;
    overflow: hidden;
}

.utm-option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 14px;
}

.utm-option:hover {
    background-color: #f9fafb;
}

.utm-preview {
    margin-top: 16px;
    padding: 12px;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 14px;
}

.utm-preview-url {
    margin-top: 20px;
    padding: 12px;
    background-color: #f0f5ff;
    border: 1px solid #d0e0ff;
    border-radius: 6px;
    font-size: 14px;
}

.utm-preview-url .utm-preview-header {
    color: #2a5298;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
}

.utm-preview p {
    margin-bottom: 8px;
    font-size: 14px;
}

.utm-params {
    background-color: white;
    padding: 8px 10px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    font-family: monospace;
    font-size: 13px;
    margin: 8px 0;
    word-break: break-all;
    overflow-wrap: break-word;
}

.utm-preview-url .utm-params {
    background-color: white;
    border: 1px solid #c0d0f0;
    word-break: break-all;
    max-width: 100%;
    overflow-x: auto;
}

.utm-description {
    font-size: 13px;
    color: #6b7280;
    line-height: 1.4;
    margin-top: 8px;
}

.utm-description ul {
    margin-left: 20px;
    list-style-type: disc;
    margin-top: 6px;
    margin-bottom: 6px;
}

.utm-description li {
    margin-bottom: 4px;
}

.utm-example {
    background-color: #f8fafc;
    border: 1px solid #e5e7eb;
    padding: 12px 16px;
    border-radius: 8px;
    margin-top: 16px;
}

/* Стили для блока предпросмотра URL */
.url-preview {
    margin-top: 16px;
    padding: 16px;
    background-color: #e8f4ff;
    border: 1px solid #b3d8ff;
    border-left: 4px solid #2563eb;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.url-preview:hover {
    background-color: #dceeff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.url-preview-header {
    color: #1e40af;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.url-preview-header::before {
    content: '🔗';
    margin-right: 8px;
}

.url-preview .utm-params {
    background-color: white;
    padding: 10px 12px;
    border: 1px solid #b3d8ff;
    border-radius: 4px;
    font-family: monospace;
    font-size: 13px;
    margin: 8px 0;
    word-break: break-all;
    overflow-wrap: break-word;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.url-example-container {
    margin-top: 12px;
}

.url-original, .url-transformed {
    width: 100%;
    overflow-x: auto;
    font-family: monospace;
}

.url-transformed {
    background-color: #eff6ff;
    border-color: #bfdbfe;
} 