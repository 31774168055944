.news-header-container {
    display: flex;
    gap: 16px;
    max-width: 100%;
    background-color: #f1f5f9;
    padding: 0;
}

.news-header-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.news-header {
    background-color: #f8fafc;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 16px;
    width: 100%;
}

.news-header-content-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
    flex-wrap: nowrap;
    min-width: 800px;
}

.news-header-section-left {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 120px;
}

.news-header-section-center {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    overflow: hidden;
}

.news-header-section-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    min-width: 360px;
    max-width: 450px;
    transition: width 0.3s ease;
}

.news-header-title {
    font-size: 24px;
    font-weight: 600;
    color: #1e293b;
    margin: 0;
}

.news-header-stats-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    background-color: #eef2ff;
    border-radius: 8px;
    border: 1px solid #e0e7ff;
    overflow: hidden;
    max-width: 100%;
}

.news-header-stats-title {
    font-size: 14px;
    color: #4f46e5;
    font-weight: 500;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-header-stats-content {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #4f46e5;
    flex-shrink: 0;
    overflow: hidden;
}

.news-header-stats-item {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #4f46e5;
}

.news-header-stats-active {
    color: #16a34a;
    font-weight: 600;
}

.news-header-stats-inactive {
    color: #dc2626;
    font-weight: 600;
}

.news-header-stats-divider {
    color: #94a3b8;
    padding: 0 8px;
}

.news-header-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.news-header-primary-button {
    background-color: #2a5298;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    white-space: nowrap;
    flex: 0 0 auto;
    width: 170px;
    text-align: center;
    min-width: 170px;
}

.news-header-primary-button:hover {
    background-color: #1e3c72;
}

/* Table styles */
.news-table-container {
    overflow-x: auto;
    margin-top: 16px;
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background-color: white;
}

.news-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

/* Main section styles */
.news-main-section {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 1;
}

/* Empty state styles */
.news-empty-state {
    text-align: center;
    padding: 40px;
    color: #6c757d;
}

/* Error state styles */
.news-error-state {
    color: red;
    text-align: center;
    padding: 20px;
}

/* Loading state styles */
.news-loading-state {
    text-align: center;
    padding: 20px;
}

/* Container styles */
.news-container {
    display: flex;
    gap: 16px;
    max-width: 100%;
    background-color: #f1f5f9;
    padding: 0;
}

.news-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Стили для компонента выбора даты */
.date-range-picker {
    flex: 0 0 auto;
    position: relative;
    transition: width 0.3s ease;
    width: 170px;
    display: flex;
    justify-content: center;
}

.date-range-picker.expanded {
    width: auto !important;
    min-width: 220px !important;
    justify-content: flex-start;
}

.date-range-picker button {
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
    transition: all 0.3s ease;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.date-range-picker button.date-range-expanded {
    width: auto !important;
    min-width: 220px !important;
    max-width: 280px !important;
    padding-right: 12px;
    justify-content: flex-start;
}

.date-range-picker button span {
    transition: all 0.3s ease;
    margin-left: 6px;
}

.date-range-picker-container {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: auto;
    min-width: 170px;
    transition: width 0.3s ease;
}

.date-range-picker-container.expanded {
    width: auto;
    min-width: 220px;
}

/* Стили для выпадающего календаря */
.date-range-picker .absolute {
    z-index: 50; /* Выше других элементов */
    right: 0;
    width: 600px;
    max-width: 90vw; /* Не больше 90% ширины окна */
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Исправляем стили ячеек календаря */
.date-range-picker .grid-cols-7 button {
    width: 100%;
    justify-content: center;
    text-align: center;
}

/* Исправляем стили для дат следующего месяца */
.date-range-picker .text-gray-400 {
    opacity: 0.6;
}

/* Исправляем стили для раздела быстрого выбора */
.date-range-picker .w-48 {
    width: 12rem;
}

.date-range-picker .w-48 .space-y-1 {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.date-range-picker .w-48 button {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    padding: 6px 8px;
}

@media (max-width: 640px) {
    .date-range-picker .absolute {
        width: 90vw;
        right: -45vw; /* Центрирование на маленьких экранах */
    }
    
    .date-range-picker .absolute .flex {
        flex-direction: column; /* Колонка вместо строки на маленьких экранах */
    }
    
    .date-range-picker .absolute .w-48 {
        width: 100%;
        border-left: none;
        border-top: 1px solid #e2e8f0;
    }
    
    .date-range-picker .w-48 .space-y-1 {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

/* Медиа-запросы для адаптивности */
@media (max-width: 1200px) {
    .news-header-stats-container {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .news-header-stats-content {
        flex-wrap: wrap;
    }
}

@media (max-width: 1024px) {
    .news-header-content-inner {
        min-width: 700px;
    }
    
    .news-header-section-right {
        width: 360px;
    }
    
    .news-header-section-left {
        width: 100px;
    }
}

@media (max-width: 900px) {
    .news-header-container {
        overflow-x: auto;
    }
    
    .news-header-section-right {
        min-width: 360px;
    }
    
    .date-range-picker button.date-range-expanded {
        max-width: 250px !important;
    }
}

@media (max-width: 768px) {
    .news-header-primary-button {
        width: 120px;
        font-size: 14px;
        padding: 8px 12px;
    }
    
    .date-range-picker-container,
    .date-range-picker {
        width: 120px;
    }
    
    .date-range-picker button {
        padding: 8px 10px;
    }
    
    .news-header-section-right {
        width: 260px;
    }
}

/* Стили для блока языков в сайдбаре */
.language-stats-container {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.language-stats-container:hover {
    background-color: #f3f4f6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.language-stats-container .text-green-600 {
    color: #16a34a;
    font-weight: 600;
    font-size: 10px;
}

.language-stats-container .text-red-600 {
    color: #dc2626;
    font-weight: 600;
    font-size: 10px;
}

.language-stats-container .bg-green-500 {
    background-color: #22c55e;
    transition: all 0.3s ease;
    min-width: 24px;
}

.language-stats-container .bg-red-500 {
    background-color: #ef4444;
    transition: all 0.3s ease;
    min-width: 24px;
}
