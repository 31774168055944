.tools-container {
    padding: 0 24px 24px 0;
    margin: 0;
    width: 100%;
}

.tools-header {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
}

.tools-title {
    font-size: 20px;
    font-weight: 600;
    color: #1e3c72;
    margin: 0;
}

.tools-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #1e3c72;
}

.help-text {
    display: block;
    font-size: 12px;
    color: #666;
    font-weight: normal;
    margin-top: 4px;
}

textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.4;
    resize: vertical;
    background-color: #f9fafb;
    transition: all 0.2s ease;
    min-height: 120px;
}

textarea:focus {
    outline: none;
    border-color: #2a5298;
    box-shadow: 0 0 0 2px rgba(42, 82, 152, 0.1);
    background-color: #fff;
}

.form-actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.btn-primary {
    background-color: #2a5298;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.btn-primary:hover {
    background-color: #1e3c72;
}

.btn-primary:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
}

.message {
    padding: 12px 16px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 14px;
}

.message.success {
    background-color: #f0fdf4;
    color: #166534;
    border: 1px solid #bbf7d0;
}

.message.error {
    background-color: #fef2f2;
    color: #991b1b;
    border: 1px solid #fecaca;
}

@media (max-width: 640px) {
    .tools-container {
        padding: 16px;
    }
    
    .tools-header,
    .tools-content {
        padding: 16px;
    }
    
    .btn-primary {
        width: 100%;
    }
} 