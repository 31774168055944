/* ActionButtons Styles */
.action-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 280px;
    min-width: 280px;
    padding: 4px;
}

.action-row {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    width: 100%;
}

.info-row {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #64748b;
}

.info-label {
    font-size: 12px;
    color: #94a3b8;
}

.info-value {
    font-size: 12px;
    color: #1e293b;
}

.action-link {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 1px;
    border: none;
    background-color: transparent;
    color: #475569;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.action-link:hover {
    background-color: #f1f5f9;
}

.menu-wrapper {
    position: relative;
    display: inline-block;
}

.menu-button {
    padding: 4px 8px;
    border: none;
    background-color: transparent;
    color: #64748b;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    transition: color 0.2s ease;
}

.menu-button:hover {
    color: #475569;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.15);
    padding: 4px 0;
    z-index: 1000;
    min-width: 160px;
    cursor: pointer;
}

.menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background-color: transparent;
    color: #64748b;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
    white-space: nowrap;
}

.menu-item.delete {
    color: #dc2626;
}

.menu-item:hover {
    background-color: #f1f5f9;
}

.menu-divider {
    margin: 4px 0;
    border: none;
    border-top: 1px solid #e2e8f0;
}

/* NewsTableHeaderColumns */

/* Table Header Styles */
.table-header {
    text-align: center;
    padding: 12px 16px;
    border-bottom: 2px solid #e5e7eb;
    background-color: #f8fafc;
    color: #475569;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-right: 1px solid #e5e7eb;
    position: sticky;
    top: 0;
    z-index: 10;
    white-space: nowrap;
}

.table-header.checkbox {
    width: 40px;
    min-width: 40px;
    padding: 12px 8px;
}

.table-header.wide {
    width: 280px;
    min-width: 280px;
    padding: 12px 8px;
}

.table-header.full {
    width: 100%;
    min-width: 400px;
}

.table-header.stats {
    width: 280px;
    padding: 12px 24px;
}

.table-header.sortable {
    cursor: pointer;
}

.table-header.sortable:hover {
    background-color: #f1f5f9;
}

.table-header.compact {
    width: 180px;
    min-width: 180px;
    padding: 8px;
}

/* Table Cell Styles */
.table-cell {
    padding: 12px 16px;
    font-size: 14px;
    color: #475569;
    border-right: 1px solid #e2e8f0;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    height: 100%;
}

.table-cell.wide {
    width: 280px;
    min-width: 280px;
    padding: 12px 8px;
    text-align: left;
}

.table-cell.full {
    width: 100%;
    text-align: left;
}

.table-cell.compact {
    width: 180px;
    min-width: 180px;
    padding: 8px;
    border-right: 1px solid #e2e8f0;
}

.stats-cell {
    padding: 12px 24px;
    font-size: 14px;
    color: #475569;
    border-right: 1px solid #e2e8f0;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
}

/* Table Footer Styles */
.table-footer {
    background-color: #f8fafc;
    border-top: 2px solid #e5e7eb;
}

.footer-cell {
    padding: 8px 16px;
    vertical-align: middle;
    border-right: 1px solid #e5e7eb;
    font-size: 14px;
    color: #1e293b;
    font-weight: 600;
    background-color: #f8fafc;
    text-align: center;
}

.footer-cell.right {
    text-align: right;
}

/* Sort Arrows Styles */
.sort-arrows {
    display: flex;
    flex-direction: row;
    margin-left: 4px;
    gap: 2px;
    align-items: center;
}

.sort-arrow {
    font-size: 10px;
    color: #94a3b8;
    cursor: pointer;
    transition: color 0.2s ease;
}

.sort-arrow.active {
    color: #2a5298;
}

/* Stats Display Styles */
.stats-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.stats-row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.stats-details {
    display: flex;
    gap: 12px;
    font-size: 12px;
    color: #6B7280;
}

.stats-details span {
    white-space: nowrap;
}

.stats-period {
    font-weight: 500;
    color: #4B5563;
}

.stats-divider {
    width: 1px;
    height: 16px;
    background-color: #E5E7EB;
    margin: 0 2px;
}

.average-ctr-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.average-ctr-label {
    color: #6B7280;
    font-size: 12px;
}

.ctr-comparison {
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.ctr-comparison::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.ctr-above-average {
    color: #10B981;
}

.ctr-above-average::before {
    background-color: #10B981;
}

.ctr-below-average {
    color: #EF4444;
}

.ctr-below-average::before {
    background-color: #EF4444;
}

.ctr-average {
    color: #6B7280;
}

.ctr-average::before {
    background-color: #6B7280;
}

/* Header Content Styles */
.table-header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    white-space: nowrap;
}

/* NewsTitle Styles */
.news-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 400px;
    height: 110px;
    justify-content: space-between;
}

.news-title {
    font-size: 20px;
    color: #1e293b;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 4px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.url-container {
    background-color: #f8fafc;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #e2e8f0;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
}

.url {
    font-size: 11px;
    color: #868686;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 8px 12px;
    white-space: normal;
    word-break: break-all;
}

.url:hover {
    text-decoration: underline;
    background-color: #f1f5f9;
    border-radius: 6px;
}

/* NewsTableRow Styles */
tr {
    border-bottom: 1px solid #e2e8f0;
}

.hover\:bg-gray-50:hover {
    background-color: #f9fafb;
}

.opacity-50 {
    opacity: 0.5;
}

.news-content-wrapper {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
}

.image-container {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 8px;
    flex-shrink: 0;
}

.news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
}

.news-image:hover {
    transform: scale(1.05);
}

.checkbox-input {
    width: 16px;
    height: 16px;
    border: 1px solid #cbd5e1;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.checkbox-input:checked {
    background-color: #3b82f6;
    border-color: #3b82f6;
}

.checkbox-input:hover {
    border-color: #93c5fd;
}

.news-weight-info {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid #eee;
}

.weight-bar {
    border-radius: 2px;
    overflow: hidden;
}

.news-reach-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reach-label {
    font-size: 14px;
    color: #6B7280;
    white-space: nowrap;
}

.reach-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
}

.reach-bar-container {
    width: 100px;
    height: 6px;
    background-color: #E5E7EB;
    border-radius: 3px;
    overflow: hidden;
}

.reach-bar {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 3px;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.reach-percentage {
    font-weight: 600;
    font-size: 14px;
    color: #1F2937;
    min-width: 40px;
}

.reach-description {
    font-size: 12px;
    color: #6B7280;
}

.learning-badge {
    display: inline-block;
    background-color: #EEF2FF;
    color: #6366F1;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 4px;
}

.error-badge {
    display: inline-flex;
    align-items: center;
    padding: 2px 8px;
    background-color: #FEE2E2;
    border-radius: 12px;
    font-size: 12px;
    color: #B91C1C;
    font-weight: 500;
}

.stats-details {
    display: flex;
    gap: 12px;
    margin-top: 5px;
    font-size: 12px;
    color: #6B7280;
}

.stats-details span {
    white-space: nowrap;
}

/* Стили для блока среднего CTR */
.average-ctr-info {
    margin-top: 10px;
    padding: 6px 8px;
    background-color: #F3F4F6;
    border-radius: 6px;
    font-size: 12px;
}

.average-ctr-label {
    color: #6B7280;
    display: flex;
    justify-content: space-between;
}

.ctr-comparison {
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.ctr-comparison::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.ctr-above-average {
    color: #10B981;
}

.ctr-above-average::before {
    background-color: #10B981;
}

.ctr-below-average {
    color: #EF4444;
}

.ctr-below-average::before {
    background-color: #EF4444;
}

.ctr-average {
    color: #6B7280;
}

.ctr-average::before {
    background-color: #6B7280;
}

/* Стили для контейнера статистики в две колонки */
.news-stats-container {
    width: 100%;
}

.news-stats-columns {
    display: flex;
    gap: 15px;
    width: 100%;
}

.news-stats-column {
    flex: 1;
}

.reach-info-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.average-stats-info {
    margin-top: 6px;
    font-size: 11px;
    color: #6B7280;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

/* Остальные стили для блоков статистики */
.news-reach-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reach-top-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.reach-full-row {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}

.news-weight-column {
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 110px;
}

.weight-bar-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 2px;
}

.weight-bar-container {
    flex: 1;
    background-color: #f3f4f6;
    border-radius: 2px;
    height: 4px;
}

.weight-percentage {
    font-size: 12px;
    font-weight: 500;
    min-width: 32px;
    color: #666;
}

.weight-description {
    font-size: 11px;
    padding: 2px 6px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
}

.weight-stats {
    margin-top: 2px;
    padding-top: 4px;
    border-top: 1px solid #f3f4f6;
}

.stats-values {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.ctr-comparison-block {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid #f3f4f6;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.stats-period {
    font-size: 11px;
    color: #64748b;
    margin-bottom: 4px;
    font-weight: 500;
}

.stats-main-row {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 2px;
}

.stats-value {
    font-size: 12px;
    color: #1e293b;
}

.stats-label {
    font-size: 11px;
    color: #64748b;
}

.stats-item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.stats-ctr {
    font-size: 12px;
    color: #1e293b;
    margin-top: 2px;
}

.loading-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 100%;
    color: #64748b;
    font-size: 14px;
}

.loading-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.loading-dots-wave {
    display: flex;
    gap: 4px;
    height: 8px;
}

.dot {
    width: 6px;
    height: 6px;
    background-color: #64748b;
    border-radius: 50%;
    animation: wave 1.4s infinite ease-in-out;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes wave {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-6px);
    }
}
